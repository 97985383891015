/**
 * @file It contains scripts for languages view
 */
import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisLanguageSelection from "@/components/language-selection"
export default {
  name      : "Languages",
  components: { ThemisLanguageSelection },
  data() {
    return {
      channelLanguages: new Array()
    }
  },
  computed: {
    ...mapGetters({
      channels                           : "channels/channels",
      languages                          : "languages/languages",
      translationConfigurationsOfChannels: "channels/translationConfigurationsOfChannels",
      reporterLanguage                   : "auth/reporterLanguage"
    }),
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    hackForWatchingMultipleProperties() {
      return [this.languagesMap, this.translationConfigurationsOfChannels]
    }
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification"
    }),
    ...mapMutations({
      setReporterLanguage: "auth/setReporterLanguage"
    }),
    changeReporterLanguage(language) {
      this.showLanguagesDialog = false
      this.setReporterLanguage(language)

      this.$router.push({
        name  : "organisation-code",
        params: {
          locale : language.shortName,
          channel: this.$route.params.channel
        }
      })
    },
    computeChannelLanguages() {
      this.channelLanguages = []
      if (this.translationConfigurationsOfChannels[this.channel.id]) {
        for (const translationConfiguration of this.translationConfigurationsOfChannels[this.channel.id]) {
          const language = this.languagesMap[translationConfiguration.languageId]
          if (language) {
            this.channelLanguages.push(language)
          }
        }
      }
    }
  },
  watch: {
    hackForWatchingMultipleProperties: {
      immediate: true,
      handler  : function() {
        this.computeChannelLanguages()
      }
    }
  }
}
